.help-panel {
  font-size: .7em;
  font-weight: 300;
  position: absolute;
  height: 700px;
  width: 40%;
  right: 10%;
  top:0;
  background-color: #000000;
  padding: 20px;
  border-radius: 10px;
  border: 3px solid #999999;
  overflow: visible;
}

table.key-commands {
  border-collapse: separate;
  border-spacing: .5em;
  font-size: .9em;
}

table.key-commands td:first-child {
  width: 20%;
  text-align: center;
}

.help-panel .help-key {
  display: inline-block;
  text-align: center;
  line-height: 1.5em;
  height: 1.5em;
  width: 1.5em;
  background-color: #666666;
  border-radius: 4px;
  font-weight: 900;
  color: White;
  border: 1px solid White;
}

.help-key .svg-inline--fa.fa-w-14 {
  width: .7em;
}

.version {
  font-size: .75em;
  float:right;
  opacity: .5;
}