$one-fifth: calc(100% / 5);
$two-fifth: calc(100% / 5 * 2);
$three-fifth: calc(100% / 5 * 3);
$four-fifth: calc(100% / 5 * 4);
$five-fifth: calc(100%);

$one-point: calc(100% / 25);


@mixin flooble($rounds: 5) {
  $stripes: ();
  $color: Red;
  @for $i from 1 through 5 {
    @if $i == 5 {
      $color: #ffcc00;
    } @else {
      $color: #cccccc;
    }
    $test3: calc(#{$i} * (100% / (5 * #{$rounds})) - 2px);
    $test4: calc(#{$i} * (100% / (5 * #{$rounds})));
  
    $stripes: append($stripes, transparent, comma);
    $stripes: append($stripes, transparent $test3, comma);
    $stripes: append($stripes, $color  $test4, comma);
    $stripes: append($stripes, transparent $test4, comma);
  }
  background-image:
    repeating-linear-gradient(90deg, $stripes),
}

.player-track {
  height: 50px;
  padding-left: 200px;
  padding-right: 0;
}

.board-bg {
  width: 100%;
  height: 50px;
}
.game-board.rounds-4 .board-bg {
  @include flooble(4);
}
.game-board.rounds-5 .board-bg {
  @include flooble(5);
}
.game-board.rounds-6 .board-bg {
  @include flooble(6);
}
.game-board.rounds-7 .board-bg {
  @include flooble(7);
}
.game-board.rounds-8 .board-bg {
  @include flooble(8);
}

.score-input {
  width: 50px;
}

