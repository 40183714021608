.player-card {
  background-image: linear-gradient(#ffffff,#e0e0e0);
  min-height: 48px;
  height: 48px;
  width: 198px;
  max-width: 200px;
  color: #333333;
  line-height: 48px;
  margin-left: -200px;
  border: 1px solid #666666;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.player-card.selected {
  /* border: 2px solid #ff0000; */
  background-image: linear-gradient(#ffffcc,#ffcc00);

}

.eliminated {
  opacity: .25;
}

.player-number {
  font-family: 'Titan One', Arial, Helvetica, sans-serif;
  font-size: .9em;
  line-height: 50px;
  width: 1.5em;
  text-align: center;
  margin: 0 10px 0 6px;
  display: flexbox;
}

.player-name {
  display: flexbox;
  height: 50px;
  margin: auto 0;
  width: 72%;
  text-align: left;
}


