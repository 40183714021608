.game-board {
  width: 100%;
  height: 100%;
  background-color: #444444;
  /* background-image: url('/hideout_logo.png');
  background-position: 90% 90%;
  background-repeat: no-repeat; */
  padding: 40px;
  margin: 0;
  overflow: visible;
  min-height: 500px;
  font-size: 24px;
  color: #e0e0e0;
}

.game-board > .row {
  width: 100%;
  margin: 0;

}
.btn-round {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  padding: 0;
}
h1.maestro-title {
  color: #ffcc00;
  font-family: "Luckiest Guy", Courier;
  letter-spacing: 3px;

  transform: rotate(-90deg) translateX(-300px);
  transform-origin: center;
  font-size: 72px;
  text-shadow: black -4px 4px;
  cursor: pointer;
}  

.control-center {
  font-size: 18px;
  text-align: center;
}

.control-center .instructions {
  font-size: .6em;
  color: #ffcc00;
}

.control-center button {
  margin-bottom: 10px;
}


.control-center h3 {
  text-transform: uppercase;
  font-size: 16px;
  color: #999999;
}

div.title {
  height: 400px;
}

.add-player-button {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.add-remove-button {
  margin-left: 2px;
  margin-right: 2px;
}

.number-markers {
  font-size: .5em;
  color: #ffcc00;
  margin-left: 189px;
  margin-right: -15px;
  display: flex;

}

.number-marker {
  display: flexbox;
  width: 25%;
  text-align: right;
}

.game-board.rounds-5 .number-marker {
  width: 20%;
}
.game-board.rounds-6 .number-marker {
  width: 16.666667%;
}

