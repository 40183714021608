@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Titan+One&family=Open+Sans&display=swap');


body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #444444;
  font-family: Helvetica, Arial, sans-serif;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
