.setup-panel {
  font-size: .8em;
  position: absolute;
  left: 5%;
  top: 0;
  background-color: #999999;
  width: 40%;
  height: 700px;
  background-color: #000000;
  padding: 20px;
  border-radius: 10px;
  border: 3px solid #999999;
  }

.setup-window p {
  text-align: center;
  font-size: .8em;
}

.player-entry-field {
  margin-bottom: 4px;
}

.player-entry-field-number {
  width: 24px;
  text-align: right;
}

.setup-buttons {
  margin-top: 10px;
  display: flex;
}

.add-player-button {
  margin-left: 4px;
}

.reset-game-button {
  margin-left: 10px;
}

.start-game-button {
  margin-right: 0;
  margin-left: auto;
}